export const mutatedTubState = `subscription MutatedTubState {
  mutatedTubState {
    lastReportedTemperature
    lastReportTimestamp
    lastSeenTimestamp
    targetTemperature
    externalController
  }
}
`;
